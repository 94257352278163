import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { navigate } from 'gatsby-link'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import LoginBtn from '../atoms/loginBtn'
import LogoSvg from '../atoms/logoSvg'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.header`
  font-size: 16px;
  width: 100vw;
  background: #ffffff;
  position: fixed;
  top: 0;
  z-index: 100;
  border-bottom: solid 2px #f8f8f8;

  &.low {
    background: #f8f8f8;
  }

  .p-head-inner {
    max-width: 1366px;
    height: 80px;
    margin: 0 auto;
    padding: 10px 20px 10px 10px;
    display: flex;
    align-items: center;

    .logo-wrap {
      margin: 0 auto 0 0;

      .p-header-logo {
        height: auto;
        display: flex;
        align-items: center;

        .logo {
          margin: 0 5px 0 0;
          padding: 0;
          width: 210px;
        }
      }

      h1 {
        font-weight: 400;
        font-size: 12px;
        margin: 5px 0 0;
        color: #153758;
      }
    }

    .p-nav-area {
      display: flex;
      align-items: center;
      position: relative;
      padding: 10px 0 0;

      .nav-input {
        display: none;
      }

      #nav-content {
        display: contents;
        position: relative;

        .free-badge {
          width: 32px;
          height: auto;
          position: absolute;
          right: -7px;
          top: 7px;
        }
      }

      button {
        padding: 0 15px;
        min-width: 30px;
      }

      a.p-link {
        color: #313a38;
        display: flex;
        justify-content: center;
        font-weight: 600;
        padding: 0 25px;
        text-decoration: none;
        position: relative;

        &:after {
          display: block;
          z-index: -1;
          position: absolute;
          background-color: #efefef;
          content: '';
          width: calc(100% - 50px);
          height: 4px;
          bottom: -10px;
          border-radius: 3px;
          transition: 0.4s;
        }

        &:hover {
          opacity: 1;
          color: #ffffff;
          text-decoration: none;

          &:after {
            height: 44px;
            background-color: #3ec7b3;
            width: calc(100% - 10px);
            transition: 0.2s;
          }
        }
      }

      button {
        width: 162px;

        &.p-btn-download {
          margin-right: 20px;
        }
      }
    }
  }

  &.p-page-feature {
    // 特徴
    .p-nav-area {
      a.p-link.p-now-feature {
        &:after {
          background-color: #3ec7b3;
        }

        &:hover {
          color: #313a38;

          &:after {
            height: 4px;
            width: calc(100% - 50px);
          }
        }
      }
    }
  }

  &.p-page-benefit {
    // 導入効果
    .p-nav-area {
      a.p-link.p-now-benefit {
        &:after {
          background-color: #3ec7b3;
        }

        &:hover {
          color: #313a38;

          &:after {
            height: 4px;
            width: calc(100% - 50px);
          }
        }
      }
    }
  }

  &.p-page-function {
    // 機能
    .p-nav-area {
      a.p-link.p-now-function {
        &:after {
          background-color: #3ec7b3;
        }

        &:hover {
          color: #313a38;

          &:after {
            height: 4px;
            width: calc(100% - 50px);
          }
        }
      }
    }
  }

  &.p-page-plan {
    // 料金
    .p-nav-area {
      a.p-link.p-now-plan {
        &:after {
          background-color: #3ec7b3;
        }

        &:hover {
          color: #313a38;

          &:after {
            height: 4px;
            width: calc(100% - 50px);
          }
        }
      }
    }
  }

  ${BreakPoints.xxLarge} {
    .p-head-inner {
      .p-nav-area {
        #nav-content {
          display: contents;
          position: relative;

          .free-badge {
            top: 420px;
            right: 20px;
          }
        }

        #nav-open {
          display: inline-block;
          width: 34px;
          height: 34px;
          vertical-align: middle;
          cursor: pointer;
        }

        #nav-open span,
        #nav-open span:before,
        #nav-open span:after {
          position: absolute;
          height: 3px; /*線の太さ*/
          width: 25px; /*長さ*/
          border-radius: 3px;
          background: #3ec7b3;
          display: block;
          content: '';
          z-index: 10000;
          transition: 0.1s;
        }

        #nav-open span:before {
          bottom: -8px;
          transition: 0.3s ease-in-out;
        }

        #nav-open span:after {
          bottom: -16px;
          transition: 0.3s ease-in-out;
        }

        /*閉じる用の薄黒箇所*/

        #nav-close {
          display: none;
          position: fixed;
          z-index: 99;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: black;
          opacity: 0;
          transition: 0.3s ease-in-out;
        }

        /*メニューの中身*/

        #nav-content {
          display: block;
          overflow: auto;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 9999;
          width: 90%;
          max-width: 330px;
          height: 100%;
          background: #fff;
          transition: 0.3s ease-in-out;
          -webkit-transform: translateX(-105%);
          transform: translateX(-105%);

          a,
          button {
            display: none;
          }
        }

        #nav-input:checked {
          + #nav-open span {
            width: 0;
          }

          + #nav-open span:before {
            width: 25px;
            transform-origin: 50% 50%;
            transform: rotate(45deg) translate(-20%, 325%);
            left: 14px;
          }

          + #nav-open span:after {
            width: 25px;
            transform-origin: 50% 50%;
            transform: rotate(-45deg) translate(-20%, 325%);
            top: 0;
          }
        }

        /*チェックがついたら表示させる*/

        #nav-input:checked ~ #nav-close {
          display: block;
          opacity: 0.5;
        }

        #nav-input:checked ~ #nav-content {
          -webkit-transform: translateX(0%);
          transform: translateX(0%);
          box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);

          a,
          button {
            display: block;
            height: 50px;
            width: 80%;
            margin: 0 auto;

            &.login-btn {
              border: solid 2px #efefef;
              border-radius: 100vh;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: unset;

              img {
                margin: 0 15px 0 0;
              }
            }
          }

          a.p-link {
            padding: 10px;
            margin: 20px auto;
            padding: 10px;

            &:after {
              background-color: rgba(0, 255, 218, 0.1);
              width: 110%;
              left: -5%;
              height: 50px;
              bottom: 3px;
            }

            &:hover {
              color: #313a38;

              &:after {
                background-color: rgba(43, 213, 188, 0.3);
              }
            }
          }

          button {
            margin: 15px auto;
          }
        }
      }
    }

    &.p-page-feature {
      // 特徴
      .p-nav-area {
        a.p-link.p-now-feature {
          color: #ffffff;

          &:after {
            background-color: #3ec7b3 !important;
          }
        }
      }
    }

    &.p-page-benefit {
      // 導入効果
      .p-nav-area {
        a.p-link.p-now-benefit {
          color: #ffffff;

          &:after {
            background-color: #3ec7b3 !important;
          }
        }
      }
    }

    &.p-page-function {
      // 機能
      .p-nav-area {
        a.p-link.p-now-function {
          color: #ffffff;

          &:after {
            background-color: #3ec7b3 !important;
          }
        }
      }
    }

    &.p-page-plan {
      // 料金
      .p-nav-area {
        a.p-link.p-now-plan {
          color: #ffffff;

          &:after {
            background-color: #3ec7b3 !important;
          }
        }
      }
    }
  }
`
type Props = {
  /** クラス名 */
  className?: string
}

/**
 * サービスサイトヘッダー
 * 画面側のファイルでOfficialLayoutへ画面に対応したclass名を指定するとメニューリンクに下線が表示される
 * @constructor
 */
const OfficialHeader = (props: Props): JSX.Element => {
  const { className = '' } = props
  const routeParam1 = useRouteParam('/download/')
  const routeParam2 = useRouteParam('https://sign-up.kintaicloud.jp/sign-up')
  return (
    <Wrapper className={className}>
      <div className="p-head-inner">
        <div className="logo-wrap">
          <Link to={useRouteParam('/')} className="p-header-logo">
            <LogoSvg />
          </Link>
          <h1>客先常駐特化型のクラウド勤怠管理システム</h1>
        </div>
        <nav className="p-nav-area ">
          <input id="nav-input" type="checkbox" className="nav-input" />
          <label id="nav-open" htmlFor="nav-input">
            <span />
          </label>
          <label className="nav-input" id="nav-close" htmlFor="nav-input">
            <span />
          </label>
          <div id="nav-content">
            <Link
              to={useRouteParam('/feature/')}
              className="p-link p-now-feature"
            >
              特徴
            </Link>
            <Link
              to={useRouteParam('/benefit/')}
              className="p-link p-now-benefit"
            >
              導入効果
            </Link>
            <Link
              to={useRouteParam('/function/')}
              className="p-link p-now-function"
            >
              機能
            </Link>
            <Link
              to={useRouteParam('/plan-list/')}
              className="p-link p-now-plan"
            >
              料金
            </Link>
            <LoginBtn />
            <OfficialCommonBtn
              size="large"
              className="p-btn-download"
              onClick={() => {
                navigate(routeParam1)
              }}
            >
              資料ダウンロード
            </OfficialCommonBtn>
            <OfficialCommonBtn
              size="large"
              level="strong"
              onClick={() => window.open(routeParam2)}
            >
              アカウント作成
            </OfficialCommonBtn>
            <img
              src="/images/official/common/iconFree01.png"
              className="free-badge"
              alt="最大2ヶ月無料"
            />
          </div>
        </nav>
      </div>
    </Wrapper>
  )
}

export default OfficialHeader
