import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto 50px;
  a {
    img {
      width: 220px;
      height: auto;
    }
  }
  img {
    width: 220px;
    height: auto;
  }
  p {
    font-size: 0.875em;
    color: #78828d;
    max-width: 540px;
    padding: 0 20px;
    margin: 0 auto;
  }
`
/**
 * アプリダウンロードボタンエリア
 * @constructor
 */
const DownloadBtnArea = () => (
  <Wrapper>
    <Link
      to="https://itunes.apple.com/jp/app/id1590320141?mt=8"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="/images/official/common/bnrApp01.png"
        alt="iOSアプリダウンロードはこちら"
      />
    </Link>
    <Link
      to="http://play.google.com/store/apps/details?id=jp.clinks.kintaicloud"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="/images/official/common/bnrGoogle01.png"
        alt="Androidアプリダウンロードはこちら"
      />
    </Link>

    {/* <img
      src="/images/official/common/bnrApp00.png"
      alt="iOSアプリダウンロードはこちら"
    />
    <img
      src="/images/official/common/bnrGoogle00.png"
      alt="Androidアプリダウンロードはこちら"
    /> */}
    <p>
      ※AppleとAppleのロゴは、米国およびその他の国で登録されたApple
      Inc.の商標です。
    </p>
    <p>※Google Play および Google Play ロゴは、Google LLC の商標です。</p>
  </Wrapper>
)
export default DownloadBtnArea
