import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  .page-top {
    position: fixed;
    right: 30px;
    bottom: 70px;
    z-index: 100;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #3ec7b3;
    border: solid 4px #9aece0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    color: transparent;
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      margin-bottom: -6px;
      border-top: solid 3px #fff;
      border-right: solid 3px #fff;
      transform: rotate(-45deg);
      position: absolute;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  ${BreakPoints.medium} {
  }
`

/**
 * ページ上部スクロール
 * @constructor
 */
const ScrollTop = () => (
  <Wrapper>
    <a className="page-top" href="#pageTop">
      TOP
    </a>
  </Wrapper>
)

export default ScrollTop
