import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { ReactNode } from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  font-size: 15px;
  padding: 20px 0 30px;

  p,
  span {
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    line-height: 1em;
    vertical-align: baseline;
  }

  a {
    color: #00bfaf;
  }

  .arrow {
    padding: 0 5px;
  }

  ${BreakPoints.medium} {
    padding: 5px 10px 15px;

    p,
    span {
      max-width: 90vw;
    }
  }
`

export type ITopicPath = {
  /** タイトル */
  title: string
  /** リンク */
  link?: string
}
type IProps = {
  paths: ITopicPath[]
}

/**
 * パンくず
 * @constructor
 */
const TopicPath = ({ paths }: IProps) => (
  <Wrapper>
    {paths.map(({ title, link }, index) => {
      let node: ReactNode
      if (link) node = <Link to={link}>{title}</Link>
      else node = <span>{title}</span>
      return (
        <p key={`topic-path-${index}`}>
          {node}
          {index < paths.length - 1 && <span className="arrow">&gt;</span>}
        </p>
      )
    })}
  </Wrapper>
)

export default TopicPath
