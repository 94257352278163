import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const StyledLink = styled(Link)`
  display: block;
  text-align: center;
  font-size: 12px;
  border-left: solid 2px #efefef;
  border-right: solid 2px #efefef;
  padding: 10px 15px;
  margin: 0 10px;
  font-weight: 600;
  img {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto 3px;
  }
`

/**
 * ヘッダーログインボタン
 */
const LoginBtn = (): JSX.Element => (
  <StyledLink
    className="login-btn"
    to="https://app.kintaicloud.jp/login"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="/images/official/common/iconLogin01.png" alt="ログイン" />
    ログイン
  </StyledLink>
)
export default LoginBtn
