import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import LogoSvg from '../atoms/logoSvg'
import DownloadBtnArea from '../molecules/downloadBtnArea'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.footer`
  width: 100%;
  background: #1e3041;
  text-align: center;
  position: relative;

  .p-foot-inner {
    max-width: 1366px;
    margin: 0 auto;
    padding: 40px 10px 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .p-nav-area {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 50px;

      a {
        color: #ffffff;
        display: block;
        font-weight: 600;
        margin: 0 60px 0 0;
        text-decoration: none;
        position: relative;

        &:hover {
          text-decoration: none;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &.sub {
        a {
          font-size: 0.875em;
          color: #78828d;
          font-weight: 400;
          margin: 0 40px 0 0;
        }
      }
    }

    .p-logo-copy {
      width: 100%;
      margin: 0 0 -5px;
      color: #ffffff;
      font-size: 0.875em;
      text-align: center;
    }

    .p-logo-link {
      height: 64px;
      width: 200px;
      display: flex;
      align-items: center;
      margin: 0 auto 20px;

      .logo {
        margin: 0 5px 0 0;
        padding: 0;
        width: 200px;

        svg {
          .logo-txt {
            fill: #ffffff;
          }
        }
      }
    }

    .p-copyright {
      width: 100%;
      font-size: 0.875em;
      color: #78828d;
    }
  }

  ${BreakPoints.xLarge} {
    .p-foot-inner {
      .p-nav-area {
        display: block;
        text-align: center;

        a {
          display: inline-block;
          margin: 0;
          padding: 0 15px 10px;
        }

        &.sub {
          a {
            margin: 0;
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
  }
`

/**
 * サービスサイトフッター
 * @constructor
 */
const OfficialFooter = () => (
  <Wrapper>
    <div className="p-foot-inner">
      <div className="p-nav-area mb-040">
        <Link to={useRouteParam('/feature/')}>特徴</Link>
        <Link to={useRouteParam('/benefit/')}>導入効果</Link>
        <Link to={useRouteParam('/function/')}>機能</Link>
        <Link to={useRouteParam('/plan-list/')}>料金</Link>
        <Link to={useRouteParam('/service-faq/')}>よくあるご質問</Link>
        <Link to={useRouteParam('/gallery/')}>ギャラリー</Link>
        <Link to={useRouteParam('/download/')}>資料ダウンロード</Link>
        <Link
          to={useRouteParam('https://www.kintaicloud.jp/help')}
          target="_blank"
          rel="noreferrer"
        >
          ヘルプ
        </Link>
      </div>
      <div className="p-nav-area">
        <Link
          to={useRouteParam('https://sign-up.kintaicloud.jp/sign-up')}
          target="_blank"
          rel="noreferrer"
        >
          アカウント作成
        </Link>
        <a href="https://app.kintaicloud.jp" target="_blank" rel="noreferrer">
          ログイン
        </a>
        <a
          href={useRouteParam('https://www.kintaicloud.jp/column/')}
          target="_blank"
          rel="noreferrer"
        >
          コラム
        </a>
      </div>
      <p className="p-logo-copy anim-fade-up delay-2">
        ＼ すべての企業に、勤怠クラウドを ／
      </p>
      <Link to={useRouteParam('/')} className="p-logo-link anim-fade-up">
        <LogoSvg />
      </Link>
      <DownloadBtnArea />
      <div className="p-nav-area sub">
        <Link to={useRouteParam('/terms/')}>利用規約</Link>
        <Link to={useRouteParam('/policy/')}>プライバシーポリシー</Link>
        <Link to={useRouteParam('/externaltools/')}>
          外部送信規律に関する公表事項
        </Link>
        <Link to={useRouteParam('/law/')}>特定商取引法に基づく表記</Link>
        <Link to={useRouteParam('/contact/')}>お問い合わせ</Link>
        <a href="https://clinks.jp" target="_blank" rel="noreferrer">
          運営会社
        </a>
      </div>
      <p className="p-copyright">
        &copy;2021 CLINKS Co., Ltd. All Rights Reserved.
      </p>
    </div>
  </Wrapper>
)

export default OfficialFooter
